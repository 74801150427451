.appBar {
  flex-grow: 1;
}

.appBarFlex {
  flex: 1;
}

.appBarMenuButton {
  margin-left: -12;
  margin-right: 20;
}


.video-wrapper {
  position:inherit!important;
  background: aliceblue;
}

.appRoot {
  flex-grow: 1;
  width: 100%;
  height: calc(100% - 64px);
}

.appContainer {
  display: flex;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
}

.loginForm {
  padding: 60px 50px 40px 50px;
  background: #fff;
  border-radius: 10px;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 10;
  align-items: 'center';
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
}
.loginForm:before {
  content: '';
  width: 100%;
  height: 30px;
  border-radius: 10px;
  position: absolute;
  top: -10px;
  background: rgba(255, 255, 255, 0.6);
  left: 0;
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  z-index: 9;
}

.bologinForm.back {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  top: -20px;
  opacity: 0.8;
  z-index: -1;
}

.formControl {
  margin: 16px 0 !important;
}

.loginContainer {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;
  background: rgba(230, 230, 230, 0.6);
}

.formTitle {
  width: 100%;
  float: left;
  line-height: 46px;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #ed2553;
  position: relative;
  font-family: 'Roboto';
}

.formTitle:before {
  content: '';
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -50px;
  background: #ed2553;
}


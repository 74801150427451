body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: "#009AB0";
  color: "#fff";
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: "#009AB0";
}
